import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getVideosApi } from '../../constant';
import { headersConfig } from '../../config/config';

// Fetch videos async thunk
export const fetchVideos = createAsyncThunk(
    'videos/fetchVideos',
    async () => {
        const response = await axios.post(getVideosApi,
            {},
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);

// Videos slice
const videosSlices = createSlice({
    name: 'videos',
    initialState: {
        videos: [],
        categories: [],
        filteredVideos: [], // change this to store filtered videos
        message: {},
        isLoading: false,
        loadingButton: false,
        error: null,
    },
    reducers: {
        filteredData(state, action) {
            const categoryId = action.payload;
            if (categoryId) {
                state.filteredVideos = state.videos.filter(video => video.category_id === categoryId);
            } else {
                state.filteredVideos = state.videos;
            }
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVideos.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchVideos.rejected, (state, action) => {
                state.error = action.error;
                state.isLoading = false;
            })
            .addCase(fetchVideos.fulfilled, (state, action) => {
                state.videos = action.payload.videos;
                state.categories = action.payload.categories;
                state.filteredVideos = action.payload.videos; // update filteredVideos on fetch success
                state.isLoading = false;
            });
    },
});

export const {
    filteredData,
    setLoadingButton,
} = videosSlices.actions;

export default videosSlices.reducer;
