import { configureStore } from '@reduxjs/toolkit'
import usersSlice from './slices/users-slices'
import productsSlices from './slices/products-slices'
import cartSlices from './slices/cart-slices'
import ordersSlices from './slices/orders-slices'
import videosSlices from './slices/videos-slices'
import branchesSlices from './slices/branches-slices'

export const store = configureStore({
  reducer: {
    users: usersSlice,
    products: productsSlices,
    cart: cartSlices,
    orders: ordersSlices,
    videos: videosSlices,
    branches: branchesSlices,
  },
})
