import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { loginApi, memberDataApi } from '../../constant';
import Cookies from 'js-cookie';
import { headersConfig } from '../../config/config';


export const userLogin = createAsyncThunk(
  'users/userLogin',
  async (user) => {
    const response = await axios.post(loginApi, user);
    return response.data;
  }
);

export const fetchMemberData = createAsyncThunk(
  'users/fetchMemberData',
  async () => {
    const response = await axios.post(memberDataApi,
      { id: Cookies.get('id') },
      {
        headers: headersConfig.headers,
      },

    );
    return response.data;
  }
);


const usersSlice = createSlice({
  name: 'users',
  initialState: {
    message: {},
    code: '',
    // code: '149025889',
    memberData: {},
    loadingButton: false,
    error: null,
  },
  reducers: {
    setLoadingButton(state, action) {
      state.loadingButton = action.payload;
    },
    // inputs data
    setCode(state, action) {
      state.code = action.payload;
    },
  },


  extraReducers: (builder) => {
    // login
    builder.addCase(userLogin.pending, (state, action) => {
      state.loadingButton = true;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.loadingButton = false;
      state.error = action.error;
      const newMsg = {
        type: 'error',
        text: 'الكود غير صحيح',
      };
      state.message = newMsg;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      Cookies.set('token', action.payload.token, { expires: 365 }); // 'expires' sets the cookie expiration in days
      Cookies.set('code', action.payload.code, { expires: 365 });
      Cookies.set('id', action.payload.id, { expires: 365 });
      state.loadingButton = false;
      window.location.replace("/");
    });
    // fetch member data
    builder.addCase(fetchMemberData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMemberData.rejected, (state, action) => {
      state.error = action.error;
      state.isLoading = false;
    });
    builder.addCase(fetchMemberData.fulfilled, (state, action) => {
      state.memberData = action.payload;
      state.isLoading = false;
    });
  },
});


export const {
  setCode,
  setLoadingButton,
} = usersSlice.actions;
export default usersSlice.reducer;
