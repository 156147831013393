import Cookies from 'js-cookie';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';



const Login = React.lazy(() => import('./pages/Login'));
const Register = React.lazy(() => import('./pages/Register'));
const Products = React.lazy(() => import('./pages/Products'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Branches = React.lazy(() => import('./pages/Branches'));
const ProductDetails = React.lazy(() => import('./pages/ProductDetails'));
const Orders = React.lazy(() => import('./pages/Orders'));
const Videos = React.lazy(() => import('./pages/Videos'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));

const ProtectedRoute = ({ element }) => {
  const token = Cookies.get('token');
  if (!token) {
    return <Navigate to="/login" />;
  }

  return element;
};

const App = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Check if the token cookie exists
    const savedToken = Cookies.get('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);
  const loading = (
    <div className='lodaingPage'>
      <div className="spinner">
        <Spinner animation="border" className='spinner-load' />
        <p>جاري التحميل...</p>
      </div>
    </div>
  );
  return (
    <Router basename="/">
      <Suspense fallback={loading}>
        <Routes>

          <Route path="/login" name="Login" element={token ? <Navigate to="/" /> : <Login />} />
          <Route path="/register" name="Register"  element={<ProtectedRoute element={<Register />} />} />
          <Route path="/product/:id" element={<ProtectedRoute element={<ProductDetails />} />} />
          <Route path="/" element={<ProtectedRoute element={<Videos />} />} />
          <Route path="/products" element={<ProtectedRoute element={<Products />} />} />
          <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
          <Route path="/branches" element={<ProtectedRoute element={<Branches />} />} />
          <Route path="/orders" element={<ProtectedRoute element={<Orders />} />} />
          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </Suspense>
    </Router>
  )
};

export default App;
