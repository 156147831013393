import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { insertProductToCartApi, getProductInCartApi, insertOrderApi, deleteProducFromCartApi, updateQuantityInCartApi } from '../../constant';
import Cookies from 'js-cookie';
import { headersConfig } from '../../config/config';


export const fetchProducts = createAsyncThunk(
    'cart/fetchProducts',
    async () => {
        const response = await axios.post(getProductInCartApi,
            { member_id: Cookies.get('id') },

            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);



export const insertProductToCart = createAsyncThunk(
    'cart/insertProductToCart',
    async (product) => {
        const response = await axios.post(insertProductToCartApi,
            product,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);
export const deleteProducFromCart = createAsyncThunk(
    'cart/deleteProducFromCart',
    async (data) => {
        const response = await axios.post(deleteProducFromCartApi,
            data,
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);
export const updateQuantityInCart = createAsyncThunk(
    'cart/updateQuantityInCart',
    async (data) => {
        const response = await axios.post(updateQuantityInCartApi,
            data,
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);

export const insertOrder = createAsyncThunk(
    'cart/insertOrder',
    async (details) => {
        const response = await axios.post(insertOrderApi,
            details,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);

const cartSlices = createSlice({
    name: 'cart',
    initialState: {
        products: [],
        name: '',
        address: '',
        phone: '',
        message: {},
        showDoneModal: false,
        isLoadingData: false,
        isLoading: false,
        error: null,
    },
    reducers: {
        setName(state, action) {
            state.name = action.payload;
        },
        setAddress(state, action) {
            state.address = action.payload;
        },
        setPhone(state, action) {
            state.phone = action.payload;
        },
        clearMessges(state, action){
            state.message = {};
        },
        setDoneModal(state, action){
            state.showDoneModal = action.payload;
        }
    },


    extraReducers: (builder) => {
        // insert product to cart
        builder.addCase(insertProductToCart.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(insertProductToCart.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث حطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(insertProductToCart.fulfilled, (state, action) => {
            state.products = action.payload;
            state.isLoading = false;
            // const newMsg = {
            //     type: 'insert',
            //     text: 'تمت الأضافة بنجاح',
            // };
            // state.message = newMsg;
        });
        // update quantity in cart
        builder.addCase(updateQuantityInCart.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(updateQuantityInCart.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
        builder.addCase(updateQuantityInCart.fulfilled, (state, action) => {
            state.products = action.payload;
            state.isLoading = false;
            // const newMsg = {
            //     type: 'update',
            //     text: 'تمت التحديث بنجاح',
            // };
            // state.message = newMsg;
        });
        // delete product from cart
        builder.addCase(deleteProducFromCart.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(deleteProducFromCart.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث حطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteProducFromCart.fulfilled, (state, action) => {
            state.products = action.payload;
            state.isLoading = false;
            // const newMsg = {
            //     type: 'insert',
            //     text: 'تمت الحذف بنجاح',
            // };
            // state.message = newMsg;
        });

        // insert new order
        builder.addCase(insertOrder.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(insertOrder.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث حطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(insertOrder.fulfilled, (state, action) => {
            state.products = [];
            state.name = '';
            state.address = '';
            state.phone = '';
            state.isLoading = false;
            state.showDoneModal = true;
            // const newMsg = {
            //     type: 'insert',
            //     text: 'تم ارسال الطلب بنجاح',
            // };
            // state.message = newMsg;
        });


        // fetch data
        builder.addCase(fetchProducts.pending, (state, action) => {
            state.isLoadingData = true;
        });
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.error = action.error;
            state.isLoadingData = false;
        });
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.products = action.payload;
            state.isLoadingData = false;
        });
    },

});


export const { setName, setAddress, setPhone, clearMessges, setDoneModal } = cartSlices.actions;
export default cartSlices.reducer;
