// const server = 'http://localhost:3000/';
const server = 'https://server.dxn.family/';
const apiLink = server + 'api/';
const imagesUrl = server + 'uploads/products/images/';
const filesUrl = server + 'uploads/products/pdfs/';

// members
const loginApi = apiLink + 'members/login';
const memberDataApi = apiLink + 'members/memberData';

// products 
const getProductsApi = apiLink + 'products/members/get-products';
const getProductDetailsApi = apiLink + 'products/get-details';


// shopping cart
const insertProductToCartApi = apiLink + 'shoppingCart/insert-product';
const getProductInCartApi = apiLink + 'shoppingCart/get-products';
const updateQuantityInCartApi = apiLink + 'shoppingCart/update-quantity';
const deleteProducFromCartApi = apiLink + 'shoppingCart/delete-product';

// orders
const insertOrderApi = apiLink + 'orders/insert-order';
const getMemberOrdersApi = apiLink + 'orders/getMember-orders';
const getOrderDetailsApi = apiLink + 'orders/get-orderDetails';
const getPendingOrdersLength = apiLink + 'orders/getPendingOrdersLength';
// videos
const getVideosApi = apiLink + 'videos/get-videosData';

// branches 
const getBranchesApi = apiLink + 'branches/get-branchData';


export {
    imagesUrl,
    filesUrl,
    // members
    loginApi,
    memberDataApi,
    // products
    getProductsApi,
    getProductDetailsApi,
    // cart
    insertProductToCartApi,
    getProductInCartApi,
    updateQuantityInCartApi,
    deleteProducFromCartApi,
    // orders
    insertOrderApi,
    getMemberOrdersApi,
    getOrderDetailsApi,
    getPendingOrdersLength,
    // videos
    getVideosApi,
    // branches
    getBranchesApi,
}