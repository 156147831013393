import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getProductsApi, getProductDetailsApi } from '../../constant';
import Cookies from 'js-cookie';
import { headersConfig } from '../../config/config';




export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async () => {
        const response = await axios.post(getProductsApi,
            { code: Cookies.get('code') },
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);

export const fetchProductDetails = createAsyncThunk(
    'products/fetchProductDetails',
    async (productId) => {
        const response = await axios.post(getProductDetailsApi,
            { product_id: productId },
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);

const productsSlices = createSlice({
    name: 'products',
    initialState: {
        products: [],
        productsList: [],
        categories: [],
        filteredCategories: [],
        productDetails: {},
        productData: {},
        currency: '',
        message: {},
        isLoading: false,
        error: null,
    },
    reducers: {
        filteredData(state, action) {
            const categoryId = action.payload.toLowerCase();
            const filtered = state.categories.filter((category) =>
                category.id.toString().toLowerCase().includes(categoryId)
            );

            state.filteredCategories = filtered;
        },
        // تحتاجها في مشاركة المنتج
        setproductData(state, action) {
            state.productData = action.payload;
        },
    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchProducts.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.products = action.payload.products;
            state.categories = action.payload.categories;
            state.filteredCategories = action.payload.categories;
            state.productsList = action.payload.productsList;
            state.currency = action.payload.currency;
            state.isLoading = false;
        });
        // product details
        builder.addCase(fetchProductDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchProductDetails.rejected, (state, action) => {
            if (action.error.code === 'ERR_BAD_REQUEST') {
                window.location = '/404';
            }
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchProductDetails.fulfilled, (state, action) => {
            state.productDetails = action.payload;
            state.isLoading = false;
        });
    },

});


export const { filteredData, setproductData } = productsSlices.actions;
export default productsSlices.reducer;
