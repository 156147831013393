import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getBranchesApi } from '../../constant';
import Cookies from 'js-cookie';
import { headersConfig } from '../../config/config';




export const fetchBranches = createAsyncThunk(
    'branches/fetchBranches',
    async () => {
        const response = await axios.post(getBranchesApi,
            { code: Cookies.get('code') },
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);


const branchesSlices = createSlice({
    name: 'branches',
    initialState: {
        branches: [],
        countries: [],
        filteredCountries: [],
        currentBranch: {},
        cities: [],
        message: {},
        isLoading: false,
        error: null,
        showVideoModal: false,
    },
    reducers: {
        filteredData(state, action) {
            const countryId = action.payload.toLowerCase();
            const filtered = state.countries.filter((country) =>
                country.id.toString().toLowerCase().includes(countryId)
            );

            state.filteredCountries = filtered;
        },
        setShowVideoModal(state, action) {
        state.showVideoModal = action.payload;
        },
        setCurrentBranch(state, action) {
        state.currentBranch = action.payload;
        },
    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchBranches.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchBranches.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchBranches.fulfilled, (state, action) => {
            state.branches = action.payload.branches;
            state.countries = action.payload.countries;
            state.cities = action.payload.cities;
            state.filteredCountries = action.payload.countries;
            state.isLoading = false;
        });
    },

});


export const { filteredData, setShowVideoModal, setCurrentBranch } = branchesSlices.actions;
export default branchesSlices.reducer;
