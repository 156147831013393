import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getMemberOrdersApi, getOrderDetailsApi, getPendingOrdersLength } from '../../constant';
import Cookies from 'js-cookie';
import { headersConfig } from '../../config/config';



export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async () => {
        const response = await axios.post(getMemberOrdersApi,
            { member_id: Cookies.get('id') },
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);
export const fetchPendingOrders = createAsyncThunk(
    'orders/fetchPendingOrders',
    async () => {
        const response = await axios.post(getPendingOrdersLength,
            { member_id: Cookies.get('id') },
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);

export const fetchOrderDetails = createAsyncThunk(
    'orders/fetchOrderDetails',
    async (orderId) => {
        const response = await axios.post(getOrderDetailsApi,
            { order_id: orderId, member_id: Cookies.get('id') },
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


const ordersSlices = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        orderDetails: {},
        products: [],
        pendingOrdersLength: 0,
        message: {},
        isLoading: false,
        isLoadingOrderDetails: false,
        error: null,
    },
    reducers: {

    },


    extraReducers: (builder) => {

        // fetch orders
        builder.addCase(fetchOrders.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchOrders.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
            state.isLoading = false;
        });
        //  fetchPendingOrders
        builder.addCase(fetchPendingOrders.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPendingOrders.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchPendingOrders.fulfilled, (state, action) => {
            state.pendingOrdersLength = action.payload.count;
            state.isLoading = false;
        });

        // fetch order details
        builder.addCase(fetchOrderDetails.pending, (state, action) => {
            state.isLoadingOrderDetails = true;
        });
        builder.addCase(fetchOrderDetails.rejected, (state, action) => {
            state.error = action.error;
            state.isLoadingOrderDetails = false;
        });
        builder.addCase(fetchOrderDetails.fulfilled, (state, action) => {
            state.orderDetails = action.payload.mapOrderDetails;
            state.products = action.payload.productDetails;
            state.isLoadingOrderDetails = false;
        });
    },

});

export default ordersSlices.reducer;
